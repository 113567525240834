import * as React from 'react';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { useToast } from '@chakra-ui/react';
import { MarkersEditor } from './MarkersEditor';
import { ImageEditor } from './ImageEditor';
import { useImageInfo } from './useImageInfo';
import { _js } from '@ifixit/localize';
import { MarkupState } from './ImageInfo';
import { uploadEditedImage } from './uploadEditedImage';
import { useMutation } from 'react-query';
import MediaLibrary from 'Shared/FrameModules/MediaLibrary/library';
import { MediaTarget } from 'Shared/FrameModules/MediaLibrary/target';
import { MarkupImage } from './MarkupImage';

type MediaImage = {
   getID(): number;
   setDataPromise(dataPromise: typeof globalThis.Future): void;
   data: {
      filter_state(): string | boolean;
   };
   getContext(): any;
};

type EventDetails = {
   mediaImage: MediaImage;
   onComplete?: () => void;
   onCancel?: () => void;
};

type MarkupMode = 'markers' | 'edit' | 'cropRequired';

interface MarkupComponentProps {
   markerColors?: string[];
   defaultMarkerColor?: string;
}

function MarkupComponent({ markerColors, defaultMarkerColor }: MarkupComponentProps) {
   const [mediaEventDetails, setMediaEventDetails] = React.useState<EventDetails | null>(null);
   const [mode, setMode] = React.useState<MarkupMode | null>(null);
   const [sourceImageElement, setSourceImageElement] = React.useState<HTMLImageElement | null>(
      null
   );

   const imageId = mediaEventDetails?.mediaImage?.getID() || null;
   const mediaItemFilterName = getMediaItemFilterName(mediaEventDetails?.mediaImage);

   function reset() {
      setMediaEventDetails(null);
      setMode(null);
      setSourceImageElement(null);
   }

   const { data, error } = useImageInfo(imageId, mediaItemFilterName);
   const sourceImageInfo = data?.srcid ? data.srcImageInfo : data;

   const createListener = React.useCallback(
      (mode: MarkupMode) => (event: CustomEvent<EventDetails>) => {
         setMediaEventDetails(event.detail);
         setMode(mode);
      },
      [setMediaEventDetails, setMode]
   );

   const openMarkersEditor = React.useMemo(() => createListener('markers'), [createListener]);
   const openImageEditor = React.useMemo(() => createListener('edit'), [createListener]);
   const openImageEditorForRequiredCrop = React.useMemo(
      () => createListener('cropRequired'),
      [createListener]
   );

   React.useEffect(() => {
      document.addEventListener('openMarkersEditor', openMarkersEditor);
      document.addEventListener('openImageEditor', openImageEditor);
      document.addEventListener('newCropRequiredOnImage', openImageEditorForRequiredCrop);

      return () => {
         document.removeEventListener('openMarkersEditor', openMarkersEditor);
         document.removeEventListener('openImageEditor', openImageEditor);
         document.removeEventListener('newCropRequiredOnImage', openImageEditorForRequiredCrop);
      };
   }, [openMarkersEditor, openImageEditor, openImageEditorForRequiredCrop]);

   const isLoading = imageId && !error && (!data || !sourceImageElement);

   const toast = useToast();
   const toastIdRef = React.useRef<string | number>();
   React.useEffect(() => {
      if (isLoading && !toast.isActive('markup-loading-toast')) {
         toast.close(toastIdRef.current);
         toastIdRef.current = toast({
            id: 'markup-loading-toast',
            title: _js('Loading image editor...'),
            status: 'loading',
            position: 'top',
            duration: null,
         });
         return;
      }
      if (error) {
         toast.update(toastIdRef.current, {
            title: _js('An error occurred!'),
            description: _js(
               'Unable to load the image markup editor. Please refresh the page and try again.'
            ),
            status: 'error',
            position: 'top',
            duration: null,
            isClosable: true,
         });
         return;
      }
      if (sourceImageElement) {
         toast.close(toastIdRef.current);
         toastIdRef.current = null;
      }
   }, [toast, isLoading, sourceImageElement, error]);

   const mutation = useMutation({
      mutationFn({ dataUrl, state }: { dataUrl: string; state: MarkupState }) {
         // upload the edited image
         return uploadEditedImage(dataUrl, state, data, mediaItemFilterName);
      },
      onMutate() {
         const mediaImageDataPromise = new globalThis.Future();
         mediaEventDetails.mediaImage.setDataPromise(mediaImageDataPromise);
         return mediaImageDataPromise;
      },
      onSuccess(imageData: any, _, mediaImageDataPromise: typeof globalThis.Future) {
         mediaImageDataPromise.resolve(imageData);
         mediaEventDetails.onComplete?.();
      },
      onError(response: Response, _, mediaImageDataPromise: typeof globalThis.Future) {
         let message = _js('Unable to upload the edited image. Please try again.');

         // It's unlikely we'll hit these error states because the user shouldn't be able to access
         // the page that renders this component. We're handling them just in case.
         if (response.status === 401) {
            message = _js('Please log in to edit images.');
         } else if (response.status === 403) {
            message = _js('You are not allowed to edit this image.');
         }

         mediaImageDataPromise.error(message);
      },
      onSettled() {
         reset();
      },
   });

   let Editor: typeof ImageEditor | typeof MarkersEditor;
   let autoPaddingEnabled: boolean;
   if (mode === 'edit' || mode === 'cropRequired') {
      Editor = ImageEditor;
      autoPaddingEnabled = mode === 'cropRequired';
   } else if (mode === 'markers') {
      if (
         !data?.filterInfo?.ratio ||
         data.filterInfo.ratio === data.ratio ||
         (!data.markupState?.edits && !data.markupState?.annotations)
      ) {
         // If the image is at the required aspect ratio, we don't have to worry about fixing the crop first.
         // If the image isn't at the required aspect ratio, but it hasn't been modified yet, we'll
         // let the MarkersEditor handle "auto padding" the image.
         Editor = MarkersEditor;
      } else {
         // We prompt the user to fix the crop if we can't "auto pad" the image.
         Editor = ImageEditor;
      }
   }

   return (
      <>
         <MarkupImage
            imageInfo={sourceImageInfo}
            imageSrc={sourceImageInfo?.image?.original}
            onLoad={image => setSourceImageElement(image)}
         />
         {Editor && data && sourceImageElement && (
            <Editor
               imageInfo={data}
               sourceImage={sourceImageElement}
               onClose={() => {
                  mediaEventDetails.onCancel?.();
                  reset();
               }}
               onRender={async (dataUrl: string, state: MarkupState) =>
                  mutation.mutate({ dataUrl, state })
               }
               // Pass editor specific props
               {...(Editor === MarkersEditor
                  ? { markerColors, defaultMarkerColor }
                  : { autoPaddingEnabled })}
            />
         )}
      </>
   );
}

initializeChakraComponent('MarkupComponent', MarkupComponent);

function getMediaItemFilterName(mediaImage?: MediaImage): string | null {
   let itemContext = mediaImage?.getContext();
   let mediaTarget = null;
   if (itemContext === MediaLibrary) {
      mediaTarget = MediaLibrary.mediaTarget;
   } else if (globalThis.instanceOf(itemContext, MediaTarget)) {
      mediaTarget = itemContext;
   }
   return mediaTarget?.getMediaFilterName() || null;
}
