import { CSRF } from 'Shared/csrf';
import { ImageInfo, MarkupState } from './ImageInfo';

export async function uploadEditedImage(
   dataUrl: string,
   state: MarkupState,
   previousImageInfo: ImageInfo,
   mediaItemFilterName: string
) {
   const blob = await (await fetch(dataUrl)).blob();
   // Ex: image/png
   const mime = blob.type;
   const extension = mime.split('/')[1];
   const filename =
      previousImageInfo.exif?.FileName || `edited-image-${previousImageInfo.image.id}.${extension}`;

   const formData = new FormData();
   formData.append('file', blob, filename);

   formData.append(
      'json',
      JSON.stringify({
         imageid: previousImageInfo.image.id,
         edits: state.edits || null,
         annotations: state.annotations || null,
         mediaItemFilterName,
      })
   );

   const response = await fetch(`/api/2.0/user/media/images/edits?csrf=${CSRF.get()}`, {
      method: 'POST',
      body: formData,
   });

   // We want to throw on a bad response so the mutation's onError handler is called.
   if (!response.ok) {
      throw response;
   }

   return response.json();
}
