import * as React from 'react';
import { ImageInfo, MarkupState } from './ImageInfo';
import { CropArea, CropAreaState, IAspectRatio, Activator } from 'cropro';
import { convertSuggestedCropToCropAreaState, toCropAreaState } from './toCropAreaState';
import { useToast } from '@chakra-ui/react';
import { _js } from '@ifixit/localize';
import { editorZIndex } from './shareStyles';
import { MarkupImage } from './MarkupImage';
import { color } from '@dozuki/web-js/primitives';
import { usePaddedImageDetails } from './usePaddedImageDetails';

// Enable CROPRO license. Removes the logo from the editor.
// This key is already exposed since we have to set this on the frontend. It's low risk so we
// hardcode it for simplicity.
Activator.addKey('CRPR-F992-S846-6443');

const mediaRatioToCropAreaRatio: Record<string, IAspectRatio> = {
   FOUR_THREE: { horizontal: 4, vertical: 3 },
   ONE_ONE: { horizontal: 1, vertical: 1 },
};

type ImageEditorProps = {
   imageInfo: ImageInfo;
   sourceImage: HTMLImageElement | null;
   onClose: () => void;
   onRender: (dataUrl: string, state: MarkupState) => void;
   autoPaddingEnabled?: boolean;
};

export function ImageEditor({
   imageInfo: originalImageInfo,
   sourceImage,
   onClose,
   onRender,
   autoPaddingEnabled,
}: ImageEditorProps) {
   const toast = useToast();
   const tooSmallToastIdRef = React.useRef<string | number>();

   React.useEffect(() => {
      // This will override the css variable that gets set by the toast component.
      document.documentElement.style.setProperty(
         '--toast-z-index',
         (Number(editorZIndex) + 1).toString(10)
      );

      return () => {
         // Remove the override when this component unmounts
         document.documentElement.style.removeProperty('--toast-z-index');
      };
   }, []);

   const [imageInfo, setImageInfo] = React.useState<ImageInfo>(originalImageInfo);

   const {
      shouldUsePaddedImage,
      paddedImageInfo,
      paddedImageSrc,
      paddedCropAreaState,
      convertPaddedStateToSourceState,
   } = usePaddedImageDetails(imageInfo, sourceImage);

   const [paddedImage, setPaddedImage] = React.useState<HTMLImageElement | null>(null);

   const isAutoPadded =
      autoPaddingEnabled &&
      shouldUsePaddedImage &&
      !imageInfo.markupState?.edits &&
      !imageInfo.markupState?.annotations;

   React.useEffect(() => {
      if (!sourceImage || (shouldUsePaddedImage && !paddedImage)) {
         return;
      }

      let aspectRatioToastId: string | number;
      let previousAnnotationsToastId: string | number;

      const cropArea = new CropArea(paddedImage || sourceImage);

      let previousState = paddedCropAreaState || toCropAreaState(imageInfo);

      cropArea.renderAtNaturalSize = true;
      if (imageInfo.encoding === 'jpeg') {
         cropArea.renderImageType = 'image/jpeg';
         cropArea.renderImageQuality = 1;
      }

      cropArea.zoomToCropEnabled = false;
      cropArea.displayMode = 'popup';

      const filterRatio = imageInfo.filterInfo?.ratio;
      const requiredAspectRatio = mediaRatioToCropAreaRatio[filterRatio];
      if (requiredAspectRatio && !isAutoPadded) {
         cropArea.aspectRatios = [requiredAspectRatio];

         if (imageInfo.ratio !== filterRatio) {
            aspectRatioToastId = toast({
               id: 'image-editor-aspect-ratio-warning',
               title: _js('A different aspect ratio is required for this image.'),
               description: _js(
                  'Please save the image with the suggested crop, or adjust and save to proceed. Otherwise, exit the editor to cancel.'
               ),
               status: 'warning',
               isClosable: true,
               duration: 20000,
               position: 'top',
            });

            // If we need to use a padded image we'll already have the paddedCropAreaState calculated.
            if (!shouldUsePaddedImage) {
               // We don't want to restore state with a crop at the wrong aspect ratio, so we ignore
               // any previous state. This effectively lets the user start over from the source image
               // with the crop aspect ratio fixed to what is required and a suggested crop.
               previousState = convertSuggestedCropToCropAreaState(imageInfo);
            }
         }
      }

      // The media manager modal has a large z-index, so we need an even bigger one when opening
      // this editor from an image in the media manager :/
      cropArea.styles.settings.zIndex = editorZIndex;

      // Help the crop frame stand out from white padding that may be present.
      cropArea.styles.settings.cropFrameColor = color.gray[200];

      cropArea.addCloseEventListener(() => {
         onClose();
         setPaddedImage(null);
         toast.close(tooSmallToastIdRef.current);
      });

      cropArea.addRenderEventListener((dataUrl, state) => {
         toast.close(tooSmallToastIdRef.current);

         const data = convertPaddedStateToSourceState(state);

         const { isTooSmall, minWidth, minHeight } = cropIsTooSmall(data, imageInfo);
         if (isTooSmall) {
            tooSmallToastIdRef.current = toast({
               title: _js('Cannot save image edits!'),
               description: _js(
                  'The chosen crop is too small. Please adjust the crop and try again. The image should have a minimum width of %1 pixels and a minimum height of %2 pixels.',
                  minWidth.toString(10),
                  minHeight.toString(10)
               ),
               status: 'error',
               isClosable: true,
               duration: 20000,
               position: 'top',
            });

            // Reset the image info with the latest edits state so the editor opens again.
            // Unfortunately, CROPRO doesn't support a way to natively limit the crop rect size or
            // disable the save button. So the best we can do is reopening the editor after the user
            // attempts to save.
            setImageInfo({
               ...imageInfo,
               markupState: {
                  edits: {
                     type: 'CROPRO',
                     version: '1',
                     data,
                  },
               },
            });

            return;
         }

         setPaddedImage(null);

         onRender(dataUrl, {
            edits: {
               type: 'CROPRO',
               version: '1',
               data,
            },
            // We blow away any annotations when the image is edited
            annotations: null,
         });
      });

      if (isAutoPadded) {
         cropArea.renderState(previousState);
      } else {
         cropArea.show();
         if (previousState) {
            cropArea.restoreState(previousState);
         }
      }

      if (imageInfo.markupState?.annotations) {
         previousAnnotationsToastId = toast({
            title: _js('Editing this image will remove any existing markers.'),
            description: _js(
               'Markers cannot be copied to the newly edited image and must be manually reapplied after your changes are saved. You can exit this editor without saving to cancel.'
            ),
            status: 'info',
            isClosable: true,
            duration: 20000,
            position: 'top',
         });
      }

      return () => {
         cropArea.close();
         toast.close(aspectRatioToastId);
         toast.close(previousAnnotationsToastId);
      };
   }, [
      sourceImage,
      imageInfo,
      onClose,
      onRender,
      toast,
      shouldUsePaddedImage,
      paddedImage,
      paddedImageInfo,
      paddedCropAreaState,
      convertPaddedStateToSourceState,
   ]);

   return (
      <MarkupImage
         imageInfo={paddedImageInfo}
         imageSrc={paddedImageSrc}
         onLoad={image => setPaddedImage(image)}
      />
   );
}

function cropIsTooSmall(cropAreaState: CropAreaState, imageInfo: ImageInfo) {
   const sourceImageInfo = imageInfo.srcImageInfo || imageInfo;
   const filterInfo = imageInfo.filterInfo;
   const scale = sourceImageInfo.width / cropAreaState.width;

   const cropWidth = scale * cropAreaState.cropRect.width;
   const cropHeight = scale * cropAreaState.cropRect.height;
   const minWidth = filterInfo?.width ? Number(filterInfo.width) : null;
   const minHeight = filterInfo?.height ? Number(filterInfo.height) : null;

   return {
      isTooSmall: (minWidth && cropWidth < minWidth) || (minHeight && cropHeight < minHeight),
      minWidth,
      minHeight,
   };
}
